<template>
  <div>
    <d-body-top-bar :title="$t('views.top-bar.users.users-management')">
      <div slot="tree">
        <label class="inactive-tree">
          {{ $t('views.top-bar.users.your-users') }} /
        </label>
        <label class="bg-doinsport-primary ml-1"> {{ $t('views.top-bar.users.all-users') }}</label>
      </div>
    </d-body-top-bar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-12">
          <b-row class="row-style" no-gutters>
            <b-col class="mt-2 ml-3" :cols="getTopNavMenuCol()">
              <d-body-nav-bar
                :tabs="$store.getters['menu/getTabs'].usersList"
              />
            </b-col>
            <b-col v-if="$store.getters['layout/getInnerWidth'] > 1332" class="mt-3 text-right mr-2" cols="4">
              <d-button
                text="views.top-bar.users.new-user"
                class="ml-2 d-btn btn d-btn-primary font-text-title"
                icon="fa fa-user-plus mr-1"
                @on:button-click="newUserClub"
              />
            </b-col>
            <b-col v-if="$store.getters['layout/getInnerWidth'] > 1332" class="mt-3 mr-3">
              <div class="has-search">
                <span class="fa fa-search form-control-feedback"></span>
                <input
                  type="text"
                  class="form-control"
                  v-model="search"
                  @keyup="manualFilter"
                  :placeholder="$t('components.doinsport.table.search')"
                >
              </div>
            </b-col>
          </b-row>
          <b-row
            v-if="$store.getters['layout/getInnerWidth'] < 1332"
            class="pt-2 background-white"
          >
            <b-col
              :class="getColsClasses()"
              align="left"
            >
              <d-button
                text="views.top-bar.users.new-user"
                class="ml-2 d-btn btn d-btn-primary font-text-title"
                icon="fa fa-user-plus mr-1"
                @on:button-click="newUserClub"
              />
            </b-col>
            <b-col align="left" :class="getColsClasses()">
              <div class="btn-full-width has-search">
                <span class="fa fa-search form-control-feedback"></span>
                <input
                  type="text"
                  class="form-control"
                  v-model="search"
                  @keyup="manualFilter"
                  :placeholder="$t('components.doinsport.table.search')"
                >
              </div>
            </b-col>
          </b-row>
          <b-card class="custom-card-padding">
            <d-table
              identifier="user"
              :tablefields="tableFields"
              :items="items"
              :totalRows="totalRows"
              :per-page="itemsPerPage"
              :is-busy="isBusy"
              :tr-cursor-pointer="false"
              :per-page-enabled="true"
              @current-page:update="onCurrentPageUpdate"
              @per-page:update="onPerPageUpdate"
              @on:sort-by:item="onSort"
              @on:data-table-btn:edit="updateUserClub"
              @on:user:delete="onUserDelete"
            >
            </d-table>
          </b-card>
        </div>
      </div>
    </div>
    <create-or-update-user
      v-if="isDisplayed"
      :display="display"
      :user="user"
      @hidden="onHide"
    />
  </div>
</template>
<script>
import UserClub from "@/classes/doinsport/UserClub";
import {deleteUserClub, getClubCoachs} from "@api/doinsport/services/user-club/user-club.api";
import {SUCCESS} from "@plugins/flash";

export default {
  components: {
    CreateOrUpdateUser: () => import('@views/top-bar/users/create-or-update/Index')
  },
  data: () => ({
    user: new UserClub(),
    display: false,
    isBusy: true,
    visible: false,
    items: [],
    search: '',
    filters: '',
    itemsPerPage: 10,
    selectedTab: null,
    timer: null,
    lastOrder: null,
    currentPage: 1,
    totalRows: 1,
    isDisplayed: false,
  }),
  computed: {
    tableFields() {
      return [
        {key: "preview", class: 'no', label: '', sortable: false},
        {key: "firstName", label: this.$t("views.client.index.firstName"), sortable: true},
        {key: "lastName", label: this.$t("views.client.index.lastName"), sortable: true},
        {key: "phoneNumber", label: this.$t("views.client.index.phoneNumber"), sortable: true},
        {key: "email", label: this.$t("views.client.index.email"), sortable: true},
        {key: "created", label: this.$t("views.client.index.createdAt"), sortable: true},
        {key: "actions", label: this.$t('general.actions.actions')},
      ];
    }
  },
  methods: {
    manualFilter() {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.loadCoachs();
      }, 400);
    },
    onUserDelete(user) {
      deleteUserClub(user.id)
        .then(() => {
          this.$flash(null, this.$t('views.top-bar.users.create-or-update.modal-user-deleted'), 3000, SUCCESS);
        })
        .finally(() => {
          this.loadCoachs();
        })
      ;
    },
    onSort(item) {
      if (item.sortBy !== '') {
        this.lastOrder = item;
        this.loadCoachs();
      }
    },
    getFilters() {
      const sortBy = this.lastOrder.sortBy === 'created' ? 'createdAt' : this.lastOrder.sortBy;
      return this.lastOrder.sortDesc ? '&order[' + sortBy + ']=desc' : '&order[' + sortBy + ']=asc';
    },
    onHide() {
      this.display = false;
      this.isDisplayed = false;

      this.loadCoachs();
    },
    newUserClub() {
      this.user = new UserClub();
      this.displaySideBar();
    },
    displaySideBar() {
      this.isDisplayed = true;

      this.$nextTick(
        () => {
          setTimeout(() => {
            this.display = true;
          }, 50);
        })
      ;
    },
    updateUserClub(user) {
      this.user = new UserClub(user.data, {deserialize: true});
      this.displaySideBar();
    },
    onCurrentPageUpdate(page) {
      this.currentPage = page;
      this.loadCoachs();
    },
    onPerPageUpdate(itemsPerPage) {
      this.itemsPerPage = itemsPerPage;
      this.currentPage = 1;
      this.loadCoachs();
    },
    loadCoachs() {
      this.isBusy = true;
      let filters = '';

      if (this.lastOrder) {
        filters = this.getFilters();
      }

      getClubCoachs(this.itemsPerPage, this.currentPage, this.search, this.filters + filters)
        .then((response) => {
          this.totalRows = response.data['hydra:totalItems'];
          this.items = [];

          for (const client of response.data['hydra:member']) {
            let item = {
              id: client.id,
              preview: client.avatar ? client.avatar : null,
              firstName: client.firstName,
              lastName: client.lastName,
              phoneNumber: client.phoneNumber,
              email: client.email,
              created: this.$moment(client.createdAt).format('DD/MM/YYYY - HH:mm'),
              data: client,
              actions: ['edit', 'delete']
            }
            this.items.push(item);
          }
          this.isBusy = false;
        })
      ;
    },
    getColsClasses() {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (width < 1326 && width >= 924) {
        return '';
      } else {
        return 'mt-3';
      }
    },
    getTopNavMenuCol() {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (width < 1326) {
        return '12';
      } else {
        return '6';
      }
    },
    getNewCols() {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (width < 1326 && width >= 924) {
        return '4';
      } else {
        return '6';
      }
    },
    getBtnsCols() {
      const width = this.$store.getters['layout/getInnerWidth'];

      if (width < 1326 && width >= 924) {
        return '2';
      } else {
        return '6';
      }
    },
  },
  mounted() {
    this.loadCoachs();
  }
}
</script>

<style lang="scss" scoped>
@import "@lazy/_b-card.scss";

/deep/ .custom-card-padding {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

</style>
