import { hydrate } from "@/utils/form";

export default class UserClub {
  id = null;
  firstName = null;
  lastName = null;
  description = null;
  email = null;
  phoneNumber = null;
  roles = ["ROLE_CLUB_COACH"];
  enabled = true;
  plainPassword = null;
  username = null;
  avatar = null;
  clubs = [JSON.parse(localStorage.getItem('selectedClub'))];

  constructor(object, options) {
    if ("undefined" !== typeof options) {
      if(options.serialize) {
        this.serialize(object);
      }
      if(options.deserialize) {
        this.deserialize(object);
      }
    }
  }

  serialize (object) {
    hydrate(this, object);
  }
  deserialize (object) {
    hydrate(this, object);
  }

}
